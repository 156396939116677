<template>
  <div class="table-informations-management">
    <div class="bottom">
      <q-table
        :data="isSearch ? search : informations"
        :columns="columns"
        :hide-bottom="true"
        :pagination.sync="pagination"
        :sort-method="customSort"
      >
        <template v-slot:body="props">
          <q-tr
            :props="props"
            @click="$router.push(`/information/${props.row.categoryName}`)"
          >
            <q-td key="name" :props="props">
              {{ props.row.categoryName }}
            </q-td>
            <q-td key="information" :props="props">
              {{ props.row.information }}
            </q-td>
            <q-td key="actions" :props="props" class="td-actions">
              <q-btn class="btn-edit" type="button"></q-btn>
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TableInformationsManagement',
  props: {
    informations: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      search: [],
      isSearch: false,
      columns: [
        {
          name: 'name',
          label: 'Uploaded material type',
          field: 'type',
          sortable: true,
          align: 'left'
        },
        {
          name: 'information',
          label: 'Extracted information items visible by default',
          field: 'information',
          sortable: true,
          align: 'left'
        },
        { name: 'actions', label: 'Actions', field: 'actions', align: 'left' }
      ],
      pagination: {
        rowsPerPage: this.informations.length
      }
    }
  },
  computed: {
    ...mapGetters('users', ['getSearch'])
  },
  methods: {
    ...mapActions('users', ['setSearchUser']),
    customSort(rows, sortBy, descending) {
      const data = [...rows]

      switch (sortBy) {
        case 'name':
          return this.handlerSort(data, descending, 'categoryName')
        case 'information':
          return this.handlerSort(data, descending, 'information')
        default:
          return data
      }
    },
    handlerSort(data, descending, sortBy) {
      return data.sort((a, b) => {
        const x = descending ? b : a
        const y = descending ? a : b

        return x[sortBy].toLowerCase() > y[sortBy].toLowerCase()
          ? 1
          : x[sortBy].toLowerCase() < y[sortBy].toLowerCase()
          ? -1
          : 0
      })
    }
  },
  watch: {
    getSearch: function(message) {
      if (message && message.length) {
        this.isSearch = true

        if (this.informations.length) {
          this.search = this.informations.filter(information => {
            if (
              information.categoryName.toLowerCase().indexOf(message) > -1 ||
              information.information.toLowerCase().indexOf(message) > -1
            ) {
              return information
            }
          })
        }
      } else {
        this.isSearch = false
      }
    }
  }
}
</script>
