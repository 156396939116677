<template>
  <div class="user-management">
    <q-page-container>
      <q-page>
        <div class="bottom">
          <div v-if="getUserPlaceholders.length">
            <TableInformationsManagement :informations="getUserPlaceholders" />
          </div>
          <LoadingOrEmpty v-else :loading="getLoadingUserPlaceholders" />
        </div>
      </q-page>
    </q-page-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TableInformationsManagement from '@/components/informations-management/TableInformationsManagement.vue'
import LoadingOrEmpty from '@/components/global/LoadingOrEmpty'

export default {
  name: 'InformationsManagement',
  components: {
    LoadingOrEmpty,
    TableInformationsManagement
  },
  computed: {
    ...mapGetters('users', [
      'getUserPlaceholders',
      'getLoadingUserPlaceholders',
      'getErrorUserPlaceholders'
    ])
  },
  created() {
    this.fetchUserPlaceholders()
  },
  methods: {
    ...mapActions('users', ['fetchUserPlaceholders']),
    showNotify(message) {
      const { color, icon, timeout, position } = this.$q.config.alert
      this.$q.notify({ color, icon, message, position, timeout })
    }
  },
  watch: {
    getErrorUserPlaceholders: function(message) {
      if (message && message.length) this.showNotify(message)
    }
  }
}
</script>
